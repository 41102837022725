.rs-table{
    border: transparent;
}

.rs-table-row-header{
    background: transparent;
}

.rs-table-row-header .rs-table-cell{
    background: #003D5B;
    color:white;
}

.rs-table-hover .rs-table-body-row-wrapper .rs-table-row:hover {
    background: transparent;
}
.rs-table-hover .rs-table-body-row-wrapper .rs-table-row:hover .rs-table-cell{
    background: #2ed8c3;
}



